import React, { useState } from "react"
import { Button, Form, FormGroup, Label, Input, Spinner } from "reactstrap"

const ContactForm = () => {
  const [message, setMessage] = useState('')
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [inquiry, setInquiry] = useState("New Order")
  const [submitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleOnSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()

    const params = {
      service_id: "mailgun-erethworks",
      user_id: "user_jazjrZBUi2Ekwyf2mtAHm",
      template_id: "erethworks",
      template_params: { message, email, name, inquiry },
    }
    const body = JSON.stringify(params)
    const handleError = (err) => {
      console.log(err)
      alert(
        "Error sending form.  Please contact us directly at info@erethworks.com"
      )
    }

    try {
      const { status } = await fetch(
        "https://api.emailjs.com/api/v1.0/email/send",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body,
        }
      )
      status === 200
        ? setSubmitted(true)
        : handleError(`Error with emailjs: ${status}`)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }

  return !submitted ? (
    <Form className="contact-form p-4" onSubmit={handleOnSubmit}>
      <FormGroup>
        <Label for="email">Email</Label>
        <Input
          required
          type="email"
          name="email"
          id="email"
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          placeholder="your@email.com"
        />
      </FormGroup>
      <FormGroup>
        <Label for="name">Name</Label>
        <Input
          required
          type="name"
          name="name"
          id="name"
          value={name}
          onChange={({ target }) => setName(target.value)}
          placeholder="Your Name"
        />
      </FormGroup>
      <FormGroup>
        <Label for="exampleSelect">Inquiry</Label>
        <Input
          type="select"
          name="select"
          value={inquiry}
          onChange={({ target }) => setInquiry(target.value)}
          id="exampleSelect"
        >
          <option>New Order</option>
          <option>Commission</option>
          <option>Other</option>
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="message">Message</Label>
        <Input
          required
          type="textarea"
          name="message"
          id="message"
          value={message}
          onChange={({ target }) => setMessage(target.value)}
        />
      </FormGroup>
      {loading ? <Spinner color="dark" /> : <Button>Submit</Button>}
    </Form>
  ) : (
    <div className="border p-4">Message Sent. We'll respond soon!</div>
  )
}

export default ContactForm
