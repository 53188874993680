import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/ContactForm"
import { Row, Col } from "reactstrap"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <Row>
      <Col className="px-4" md="6">
        <h6>Contact Us</h6>
        <p>Please contact us with any questions or to start an order.</p>

        <h6>Orders</h6>
        <p>
          All items are made to order and have an 8 to 12 week lead time. Reach
          out with the items you're interested in and we'll respond and start
          the order process with you.
        </p>

        <h6>Shipping</h6>
        <p>
          We hand deliver items within ~100 miles of the greater Portland
          Oregon area.
        </p>

        <h6>Materials</h6>
        <p>
          We use local hardwoods from the Pacific Northwest along with textiles from 
          <a href="https://www.maharam.com/"> Maharam</a>. 
        </p>

      </Col>
      <Col md="6">
        <ContactForm />
      </Col>
    </Row>
  </Layout>
)

export default ContactPage
